
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				numberPadKey: 0,
				item: null,
				name: '',
				price: '',
				quantity: 1,
				taxes: [],
				selectedTaxes: []
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			settings () {
				return this.$store.state.settings
			},
			customItemsCount () {
				return this.$store.state.cart.items.filter(i => i.itemization_type === 'custom_amount').length
			},
			weighingScale () {
				return this.$store.state.weighingScale
			},
			isWeighingScaleEnabled () {
				return this.weighingScale.enabled && this.weighingScale.serialPortOptions.path !== null
			}
		},
		watch: {
			locale () {
				requestAnimationFrame(this.$refs.validator.reset)
			}
		},
		async beforeMount () {
			const item = await this.$bridge.getItems(this.deviceId, this.locationId, this.objToJson({
				variation_id: parseInt(this.settings.sale.custom_sale)
			}))
			const taxes = await this.$bridge.getTaxes(this.deviceId, '')

			this.item = (typeof item === 'string' ? JSON.parse(item) : item).data[0]
			this.taxes = (typeof taxes === 'string' ? JSON.parse(taxes) : taxes).data
		},
		methods: {
			reset () {
				this.name = ''
				this.price = ''
				this.selectedTaxes = []
				this.quantity = 1
				this.numberPadKey++
				this.$refs.validator.reset()
			},
			async addCustomItem () {
				if (await this.$refs.validator.validate()) {
					this.item.variations[0].name = this.name || this.item.variations[0].name
					this.item.variations[0].batch_id = `BATCH-${this.customItemsCount + 1}`
					this.item.variations[0].price = this.price
					this.item.variations[0].quantity = parseFloat(this.quantity)
					this.item.variations[0].tax = this.selectedTaxes
					this.item.variations[0].itemization_type = 'custom_amount'
					this.$store.dispatch('modifyCart', {
						item: {
							id: this.item.id,
							name: this.name || this.item.name,
							category_id: this.item.category.id,
							brand_id: this.item.brand?.id || null
						},
						variation: this.item.variations[0]
					})
					this.reset()
				}
			},
			useWeighingScale () {
				this.getWeight((weight) => {
					if (weight) {
						this.quantity = weight
					} else {
						this.$swal({
							title: this.$t('weighingScaleError.title'),
							text: this.$t('weighingScaleError.text'),
							icon: 'error',
							button: this.$t('ok')
						})
					}
				})
			}
		}
	}
