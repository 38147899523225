
	export default {
		data () {
			return {
				loading: true,
				variations: {
					data: []
				},
				searchTerm: '',
				categories: [{
					id: null,
					name: 'all'
				}],
				category: null,
				filters: {},
				store: {
					isEnabled: false,
					platforms: []
				},
				freezedToggles: JSON.parse(localStorage.getItem('freezedToggles') || '[]')
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			parentMerchant () {
				return this.$store.state.merchant
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			settings () {
				return this.$store.state.settings
			},
			keyboardShortcuts () {
				return this.$store.state.keyboardShortcuts
			},
			isOnline () {
				return this.$store.state.isOnline
			}
		},
		watch: {
			isOnline () {
				if (this.isOnline) {
					this.searchTerm = ''
					this.category = null
					this.getItemsVariations()
				}
			},
			searchTerm () {
				this.getItemsVariations()
			},
			category () {
				this.searchTerm = ''
				this.getItemsVariations()
			},
			freezedToggles (value) {
				localStorage.setItem('freezedToggles', this.objToJson(value))
			}
		},
		async beforeMount () {
			if (this.isOnline) {
				await this.getCategories(null, true)
				await this.getItemsVariations()
				await this.getStoreDetails()

				setInterval(() => {
					this.unFreezeToggleButton()
				}, 1000)
			}
		},
		methods: {
			async getStoreDetails () {
				try {
					const response = await this.$axios.get(`api/pos/v2/online-platform/merchant-locations/${this.locationId}`)

					if (response.status === 200) {
						this.store.isEnabled = response.data.data.merchant_locations[0].is_enabled
						this.store.platforms = response.data.data.merchant_locations[0].platforms.map(p => ({
							...p,
							online: Boolean(p.online)
						}))
					}
				} catch (e) {
					this.$bvToast.toast(this.$tc('updateModal.title', 4), {
						title: this.$t('status'),
						toaster: 'b-toaster-bottom-right',
						autoHideDelay: 3000
					})
				}
			},
			async toggleStorePlatforms ($event, platform) {
				if (!platform) {
					this.store.platforms.forEach((p) => {
						p.online = $event
						this.freezedToggles.push({
							id: `${p.id}-${p.slug}`,
							freeze_at: new Date()
						})
					})
				} else {
					this.freezedToggles.push({
						id: `${platform.id}-${platform.slug}`,
						freeze_at: new Date()
					})
				}

				const params = {
					location_id: this.locationId,
					active: $event,
					platforms: platform ? [platform.slug] : this.store.platforms.map(p => p.slug)
				}

				try {
					const response = await this.$axios.patch('api/pos/v2/online-platform/store/toggle', params)

					if (response.status === 200) {
						this.getStoreDetails()
					}
				} catch (e) {
					this.$bvToast.toast(this.$tc('updateModal.title', 4), {
						title: this.$t('status'),
						toaster: 'b-toaster-bottom-right',
						autoHideDelay: 3000
					})
				}
			},
			async getItemsVariations (isLoadMore) {
				this.filters = {
					location_id: this.locationId,
					device_id: this.deviceId,
					query: this.searchTerm,
					category_id: this.category?.id || null,
					page: isLoadMore ? this.variations.pagination.currentPage + 1 : null,
					type: ['sale', 'combo', 'modifier']
				}
				this.loading = !this.filters.page

				try {
					const response = await this.$axios.get('/api/pos/inventory', {
						params: this.filters
					})

					this.variations.data = this.filters.page ? this.variations.data.concat(response.data.data.inventory) : response.data.data.inventory
					this.variations.pagination = response.data.data.pagination
				} catch (e) {
					this.$bvToast.toast(this.$t('oopsError.text'), {
						title: this.$t('oopsError.title'),
						toaster: 'b-toaster-bottom-right',
						autoHideDelay: 3000
					})
				}

				this.loading = false
			},
			loadMoreResults (isVisible) {
				if (isVisible) {
					this.getItemsVariations(true)
				}
			},
			async getCategories (searchTerm, beforeMount) {
				let categories = await this.$bridge.getCategories(this.deviceId, this.objToJson({
					merchant_id: this.merchant.id,
					search_term: searchTerm,
					has_item: true,
					offset: this.appVersionNumber >= 4010 ? -1 : undefined
				}))

				categories = (typeof categories === 'string' ? JSON.parse(categories) : categories).data
				this.categories = beforeMount ? this.categories.concat(categories) : categories
			},
			searchCategories (searchTerm) {
				clearTimeout(this.searchTimer)
				this.searchTimer = setTimeout(() => {
					this.getCategories(searchTerm)
				}, 100)
			},
			async processVariationAvailable ($event, variation) {
				this.freezedToggles.push({
					id: variation.id,
					freeze_at: new Date()
				})

				try {
					const response = await this.$axios.patch(`/api/pos/v2/online-platform/${variation.id}`, {
						is_available: $event,
						location_id: this.locationId,
						device_id: this.deviceId
					})

					if (response.status !== 200) {
						this.$bvToast.toast(this.$tc('updateModal.title', 4), {
							title: this.$t('status'),
							toaster: 'b-toaster-bottom-right',
							autoHideDelay: 3000
						})
						variation.isAvailable = !$event
					}
				} catch (e) {
					this.$bvToast.toast(this.$tc('updateModal.title', 4), {
						title: this.$t('status'),
						toaster: 'b-toaster-bottom-right',
						autoHideDelay: 3000
					})
					variation.isAvailable = !$event
				}
			},
			unFreezeToggleButton () {
				this.freezedToggles.forEach((t, index) => {
					if (this.$moment().diff(this.$moment(t.freeze_at), 'seconds') >= 60) {
						this.freezedToggles.splice(index, 1)
					}
				})
			}
		}
	}
