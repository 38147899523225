import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6d05abac&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=6d05abac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d05abac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Login: require('/home/ubuntu/complete-nuxt-js/components/login.vue').default,Employees: require('/home/ubuntu/complete-nuxt-js/components/employees.vue').default,Locale: require('/home/ubuntu/complete-nuxt-js/components/locale.vue').default,App: require('/home/ubuntu/complete-nuxt-js/components/app.vue').default,ProgressModal: require('/home/ubuntu/complete-nuxt-js/components/progress-modal.vue').default,LoadingModal: require('/home/ubuntu/complete-nuxt-js/components/loading-modal.vue').default,Passcode: require('/home/ubuntu/complete-nuxt-js/components/passcode.vue').default,OtpModal: require('/home/ubuntu/complete-nuxt-js/components/otp-modal.vue').default})
