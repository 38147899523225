
	import Vue from 'vue'
	import KotReceipt from '~/components/kot-receipt'
	import OrderInvoice from '~/components/order-invoice'
	import OrderInvoiceTemplate2 from '~/components/order-invoice-template2'
	import OrderInvoiceTemplate3 from '~/components/order-invoice-template3'
	import OrderInvoiceSA from '~/components/order-invoice-sa'
	import OrderInvoiceSimplified from '~/components/order-invoice-simplified'
	import OrderReceipt from '~/components/order-receipt'
	import OrderReceiptSA from '~/components/order-receipt-sa'
	import ShiftReceipt from '~/components/shift-receipt'
	import KotReceiptLabel from '~/components/kot-receipt-label'
	import PayCreditReceipt from '~/components/pay-credit-receipt'

	export default {
		async asyncData ({ req, $axios, store }) {
			if (req) {
				try {
					const response = await $axios.$get('/api/partner/partner-api/v1/partners/info', {
						headers: {
							'User-Agent': req.headers['user-agent'],
							'Content-Type': 'application/json',
							Accept: 'application/json',
							'x-partner-domain': process.env.NODE_ENV === 'development' ? 'pos-hybrid.smartbytz.com' : req.hostname,
							'x-api-key': process.env.GATEWAY_API_KEY
						}
					})
					const mainDomain = response.data.info.partner.domains.filter(d => d.type === 'main' && d.active === true)[0]

					store.commit('setState', {
						key: 'partner',
						value: {
							id: response.data.info.partner.id,
							name: response.data.info.partner.business_info.name,
							slug: response.data.info.partner.slug,
							logo_square: response.data.info.partner.settings.logo_square,
							logo_dark: response.data.info.partner.settings.logo_dark,
							domain: mainDomain && mainDomain.domain ? 'https://' + mainDomain.domain : null,
							beta_testing: response.data.info.partner.settings.beta_testing,
							crisp_id: response.data.info.partner.settings.crisp.website_id
						}
					})
				} catch (err) {
					console.error(err)
				}
			}
		},
		data () {
			return {
				key: 0,
				showInvalidTimeModal: false,
				appUpdate: {
					status: null,
					progress: null,
					error: null
				},
				env: process.env.NODE_ENV,
				releaseNotesDoc: null,
				documentationDoc: null,
				invoiceComponents: {
					OrderInvoiceTemplate2,
					OrderInvoiceTemplate3,
					OrderInvoice,
					OrderInvoiceSA
				}
			}
		},
		head () {
			return {
				title: this.partner.name
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			partner: {
				get () {
					return this.$store.state.partner
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'partner',
						save: true,
						value
					})
				}
			},
			isLoggedIn () {
				return this.$store.state.isLoggedIn
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			frontendAppVersion () {
				return process.env.VERSION
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			merchant () {
				return this.$store.state.merchant
			},
			selectedMerchant () {
				return this.$store.state.selectedMerchant
			},
			employees () {
				return this.$store.state.employees
			},
			employee () {
				return this.$store.state.employee
			},
			employeeShift () {
				return this.$store.state.employeeShift
			},
			kots () {
				return this.$store.state.kots
			},
			printerSettings () {
				return this.$store.state.printerSettings
			},
			progress () {
				return this.$store.state.progress
			},
			cart () {
				return this.$store.state.cart
			},
			settings () {
				return this.$store.state.settings
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			isOnline: {
				get () {
					return this.$store.state.isOnline
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'isOnline',
						value
					})
				}
			},
			isMiniPlan () {
				return this.$store.state.merchant?.subscription?.slug === 'mini'
			},
			selectedSecondaryDisplay () {
				return this.$store.state.selectedSecondaryDisplay
			}
		},
		watch: {
			cart: {
				handler (cart) {
					if (typeof this.$bridge.emitToWindow === 'function') {
						const data = { cart, merchant: this.merchant, settings: this.settings, selectedSecondaryDisplay: this.selectedSecondaryDisplay }

						this.$bridge.emitToWindow('secondaryDisplayWindow', 'updateUI', this.bridgeName === 'ANDROID' ? this.objToJson(data) : data)
					}

					if (typeof this.$bridge.emitToSecondaryDisplay === 'function') {
						const data = { cart, merchant: this.merchant, settings: this.settings }

						this.$bridge.emitToSecondaryDisplay(this.bridgeName === 'ANDROID' ? this.objToJson(data) : data)
					}
				},
				deep: true
			}
		},
		beforeMount () {
			Vue.prototype.$store = this.$store
		},
		mounted () {
			if (!this.isMiniPlan) {
				this.backgroundSync()
				this.repairSyncQueueCheck()
			}

			this.checkNetworkTime()
			this.initReleaseNotes()
			document.getElementById('initial-loading').remove()
			this.$root.$on('show-release-notes', () => this.initReleaseNotes(null, true))

			window.onAppReady = () => {
				this.$store.dispatch('checkForUpdates')
			}

			window.syncState = this.syncState
			window.printOrder = (order, type = 'print') => this.renderOrderReceipt(order, type)

			window.printKot = (kotData) => {
				if (kotData.price_category?.custom_attributes.devices?.length) {
					const kotItems = [].concat.apply([], Object.values(kotData.items))

					kotData.price_category.custom_attributes.devices.forEach((deviceId) => {
						if (kotData.items[deviceId]) {
							const kotDeviceIdCount = Object.keys(kotData.items).reduce((sum, key) => {
								if (key.startsWith(`${deviceId}-`)) {
									sum += 1
								}

								return sum
							}, 0)

							kotData.items[`${deviceId}-${kotDeviceIdCount}`] = kotItems
						} else {
							kotData.items[deviceId] = kotItems
						}
					})
				}

				Object.keys(kotData.items).forEach((deviceId) => {
					const kot = this.kots.find(kot => kot.devices?.some(device => device.id === +deviceId)) || (
						this.printerSettings.useDefaultKotPrinter ? this.kots[0] : null
					)

					if (kot?.isLabelPrinter) {
						kotData.total_items = 0
						kotData.items[deviceId].forEach((item) => {
							for (let i = 0; i < item.quantity; i++) {
								item.sl_no = kotData.total_items + 1
								kotData.items[`${deviceId}-${kotData.total_items++}`] = [{
									...item,
									quantity: 1
								}]
							}
						})
						delete kotData.items[deviceId]
					}
				})

				this.sendToKot(kotData)
			}

			window.printPayCredit = this.printPayCredit
			window.printShift = this.renderShiftReceipt
			window.printOrderInvoice = (order, type = 'print', invoiceType) => this.renderOrderInvoice(order, type, invoiceType)

			window.setUpdateStatus = (status) => {
				this.appUpdate = typeof status === 'string' ? JSON.parse(status) : status
			}

			window.downloadFileCallback = path => path && this.toastDownloadSuccess(path)
			document.addEventListener('keydown', this.keyboardEvents)
			this.$offline.on('up', () => {
				this.isOnline = true
			})
			this.$offline.on('down', () => {
				this.isOnline = false
			})
		},
		destroyed () {
			this.$root.$off('show-release-notes')
			document.removeEventListener('keydown', this.keyboardEvents)
		},
		methods: {
			keyboardEvents (e) {
				if ([
					'AltLeft', 'AltRight', 'ArrowRight', 'ArrowLeft', 'KeyS', 'KeyD'
				].includes(e.code)) {
					this.keystrokes += e.code

					if (this.keystrokes.includes('Alt') && e.code.includes('Arrow')) {
						this.listenArrowKey(e)
						this.keystrokes = ''
					} else if (this.keystrokes.includes('Alt') && e.code === 'KeyS') {
						this.keystrokes = ''
						setTimeout(() => {
							this.focus(null, '.searchTerm')
						}, 1)
					} else if (this.keystrokes.includes('Alt') && e.code === 'KeyD') {
						document.querySelector('#default-price-category')?.focus()
						this.keystrokes = ''
					}
				} else {
					this.keystrokes = ''
				}
			},
			syncState (mutation) {
				mutation = typeof mutation === 'string' ? JSON.parse(mutation) : mutation
				this.$store.commit(mutation.type, mutation.payload)
			},
			toastDownloadSuccess (path) {
				const ce = this.$createElement
				const messageNodes = ce(
					'div',
					[
						ce('p', [`${this.$t('downloadMessage', [path])}`]),
						ce('div', { class: ['text-right'] }, [
							typeof this.$bridge.showItemInFolder === 'function' && ce('b-button', {
								props: {
									size: 'sm',
									variant: 'outline-primary'
								},
								class: ['mr-2'],
								on: {
									click: () => {
										this.$bridge.showItemInFolder(path)
										this.$bvToast.hide()
									}
								}
							}, [this.$t('openFolder')]),
							ce('b-button', {
								props: { size: 'sm', variant: 'primary' },
								on: {
									click: () => {
										this.$bridge.openExternalUrl(`file://${path}`)
										this.$bvToast.hide()
									}
								}
							}, [this.$t('open')])
						])
					]
				)

				this.$bvToast.toast(messageNodes, {
					title: this.$t('downloadComplete'),
					toaster: 'b-toaster-bottom-right',
					solid: true,
					variant: 'secondary',
					autoHideDelay: 5000
				})
			},
			async renderOrderReceipt (order, type) {
				const merchantLogo = await this.$bridge.getLocalStorage(`merchant${this.settings.receipt.print_child_merchant_details ? order?.merchant_id || this.merchant.id : this.merchant.id}Logo`)
				const id = `${this.partner.name}-Order-Receipt-${order.ref_code || order.receipt_code.substr(-10)}-${Date.now()}`
				const body = document.querySelector('body')
				const iframe = document.createElement('iframe')
				let component = OrderReceipt

				if (['SA', 'QA', 'OM'].includes(this.merchant.countryCode)) {
					component = OrderReceiptSA
				}

				const ComponentClass = Vue.extend(component)
				const propsData = {
					i18n: this.$i18n,
					bridge: this.$bridge,
					moment: this.$moment,
					currency: this.$currency,
					domtoimage: this.$domtoimage,
					data: { id, type, order, merchantLogo }
				}
				const instance = new ComponentClass({ propsData })

				instance.$mount()

				if (typeof this.$bridge.emitToWindow === 'function') {
					await this.$bridge.emitToWindow('printWindow', 'printReceipt', {
						id,
						html: instance.$el.outerHTML,
						receiptType: 'order',
						data: propsData.data
					})
					instance.$destroy()
				} else {
					instance.$on('destroy', () => {
						body.removeChild(iframe)
						instance.$destroy()
					})
					iframe.id = id
					iframe.srcdoc = instance.$el.outerHTML
					iframe.onload = instance.renderReceipt
					body.appendChild(iframe)
				}
			},
			async sendToKot (kotData) {
				if (kotData/*  && this.kots.length */) {
					let waiter = null
					const deviceId = Object.keys(kotData.items)[0]
					const hyphenIndex = deviceId.indexOf('-')
					const kotDeviceId = +deviceId.substring(0, hyphenIndex > -1 ? hyphenIndex : deviceId.length)

					const kot = this.kots.find(kot => kot.devices?.some(device => device.id === kotDeviceId)) || (
						this.printerSettings.useDefaultKotPrinter ? this.kots[0] : null
					)

					if (!isNaN(kotData.created_by)) {
						waiter = await this.$bridge.getEmployee(kotData.created_by)
						waiter = typeof waiter === 'string' ? JSON.parse(waiter) : waiter
					}

					if (kot) {
						const id = `${this.partner.name}-KOT-Receipt-${kotData.receipt_code.substr(-10)}-${Date.now()}`
						const body = document.querySelector('body')
						const iframe = document.createElement('iframe')
						const ComponentClass = Vue.extend(kot.isLabelPrinter ? KotReceiptLabel : KotReceipt)
						const propsData = {
							i18n: this.$i18n,
							bridge: this.$bridge,
							moment: this.$moment,
							currency: this.$currency,
							domtoimage: this.$domtoimage,
							data: {
								id,
								kot,
								waiter,
								deviceId,
								order: {
									...kotData,
									items: kotData.items[deviceId]
								}
							}
						}
						const instance = new ComponentClass({ propsData })

						const processNextKot = () => {
							if (this.printerSettings.reprintKot) {
								if (kotData.items[deviceId].reprint) {
									delete kotData.items[deviceId]
								} else {
									kotData.items[deviceId].reprint = true
								}
							} else {
								delete kotData.items[deviceId]
							}

							if (Object.keys(kotData.items).length) {
								this.sendToKot(kotData)
							}
						}

						instance.$mount()

						if (typeof this.$bridge.emitToWindow === 'function') {
							await this.$bridge.emitToWindow('printWindow', 'printReceipt', {
								id,
								html: instance.$el.outerHTML,
								receiptType: 'kot',
								data: propsData.data
							})
							instance.$destroy()
							processNextKot()
						} else {
							instance.$on('destroy', () => {
								body.removeChild(iframe)
								instance.$destroy()
								processNextKot()
							})
							iframe.id = id
							iframe.srcdoc = instance.$el.outerHTML
							iframe.onload = instance.renderReceipt
							body.appendChild(iframe)
						}
					} else {
						delete kotData.items[deviceId]

						if (Object.keys(kotData.items).length) {
							this.sendToKot(kotData)
						}
					}
				}
			},
			async printPayCredit (payCredit) {
				const merchantLogo = await this.$bridge.getLocalStorage(`merchant${this.merchant.id}Logo`)
				const id = `${this.partner.name}-Credit-Receipt-${Date.now()}`
				const body = document.querySelector('body')
				const iframe = document.createElement('iframe')
				const ComponentClass = Vue.extend(PayCreditReceipt)
				const propsData = {
					i18n: this.$i18n,
					bridge: this.$bridge,
					moment: this.$moment,
					currency: this.$currency,
					domtoimage: this.$domtoimage,
					data: { id, payCredit, merchantLogo }
				}
				const instance = new ComponentClass({ propsData })

				instance.$mount()

				if (typeof this.$bridge.emitToWindow === 'function') {
					await this.$bridge.emitToWindow('printWindow', 'printReceipt', {
						id,
						html: instance.$el.outerHTML,
						receiptType: 'credit',
						data: propsData.data
					})
					instance.$destroy()
				} else {
					instance.$on('destroy', () => {
						body.removeChild(iframe)
						instance.$destroy()
					})
					iframe.id = id
					iframe.srcdoc = instance.$el.outerHTML
					iframe.onload = instance.renderReceipt
					body.appendChild(iframe)
				}
			},
			async renderShiftReceipt (data) {
				let merchantId = this.selectedMerchant ? this.selectedMerchant.id : this.merchant.id

				if (data.source === 'clockout' && this.merchant.childMerchants && this.merchant.childMerchants.length) {
					merchantId = [this.merchant.id].concat(this.merchant.childMerchants.map(m => m.id))
				}

				let cashDrawerShift = await this.$store.dispatch('bridgeCall', {
					methodName: 'getCashDrawerShifts',
					args: [this.deviceId, this.objToJson({
						employee_shift_id: data.employeeShift.id
					})]
				})

				cashDrawerShift = (
					typeof cashDrawerShift === 'string'
						? JSON.parse(cashDrawerShift)
						: cashDrawerShift
				).data[0] || this.$store.state.cashDrawerShift

				const employeeShift = await this.$store.dispatch('bridgeCall', {
					methodName: 'getEmployeeShifts',
					args: [this.deviceId, this.objToJson({ id: data.employeeShift.id })]
				})
				const cashDrawerShiftEvents = cashDrawerShift
					? await this.$store.dispatch('bridgeCall', {
						methodName: 'getCashDrawerShiftEvents',
						args: [cashDrawerShift.id]
					})
					: []
				const salesReport = await this.$store.dispatch('bridgeCall', {
					methodName: 'getSalesReport',
					args: [
						this.bridgeName === 'ANDROID'
							? this.objToJson(merchantId)
							: this.merchant.id, this.deviceId,
						((
							+this.settings.general.multi_employee_kiosk && this.employeePolicy.isAdmin
						) || data.source === 'clockout')
							? ((data.source === 'reports' && data.employeeId) || null)
							: this.employee.id,
						data.employeeShift.id
					]
				})
				const itemsSalesReport = await this.$store.dispatch('bridgeCall', {
					methodName: 'getItemsSalesReport',
					args: [
						this.bridgeName === 'ANDROID' ? this.objToJson(merchantId) : this.merchant.id,
						this.deviceId,
						((
							+this.settings.general.multi_employee_kiosk && this.employeePolicy.isAdmin
						) || data.source === 'clockout')
							? ((data.source === 'reports' && data.employeeId) || null)
							: this.employee.id,
						data.employeeShift.id
					]
				})

				data.employeeShift = employeeShift
					? (typeof employeeShift === 'string' ? JSON.parse(employeeShift) : employeeShift)[0]
					: {}
				data.cashDrawerShift = cashDrawerShift
				data.cashDrawerShiftEvents = typeof cashDrawerShiftEvents === 'string'
					? JSON.parse(cashDrawerShiftEvents)
					: cashDrawerShiftEvents
				data.salesReport = typeof salesReport === 'string' ? JSON.parse(salesReport) : salesReport
				data.itemsSalesReport = typeof itemsSalesReport === 'string'
					? JSON.parse(itemsSalesReport)
					: itemsSalesReport

				const id = `${this.partner.name}-Shift-Receipt-${data.employeeShift.shift_code.substr(-10)}-${Date.now()}`
				const body = document.querySelector('body')
				const iframe = document.createElement('iframe')
				const ComponentClass = Vue.extend(ShiftReceipt)
				const propsData = {
					i18n: this.$i18n,
					bridge: this.$bridge,
					moment: this.$moment,
					currency: this.$currency,
					domtoimage: this.$domtoimage,
					data: { id, ...data }
				}
				const instance = new ComponentClass({ propsData })

				instance.$mount()

				if (typeof this.$bridge.emitToWindow === 'function') {
					await this.$bridge.emitToWindow('printWindow', 'printReceipt', {
						id,
						html: instance.$el.outerHTML,
						receiptType: 'shift',
						data: propsData.data
					})
					instance.$destroy()
				} else {
					instance.$on('destroy', () => {
						body.removeChild(iframe)
						instance.$destroy()
					})
					iframe.id = id
					iframe.srcdoc = instance.$el.outerHTML
					iframe.onload = instance.renderReceipt
					body.appendChild(iframe)
				}
			},
			async renderOrderInvoice (order, type, invoiceType) {
				const merchantLetterHeadImage = await this.$bridge.getLocalStorage(`merchant${this.settings.receipt.print_child_merchant_details ? order?.merchant_id || this.merchant.id : this.merchant.id}LetterHeadImage`)
				const merchantLogo = await this.$bridge.getLocalStorage(`merchant${this.settings.receipt.print_child_merchant_details ? order?.merchant_id || this.merchant.id : this.merchant.id}Logo`)
				const digitalSignature = await this.$bridge.getLocalStorage(`merchant${this.settings.receipt.print_child_merchant_details ? order?.merchant_id || this.merchant.id : this.merchant.id}digitalSign`)
				const merchantdigitalStamp = await this.$bridge.getLocalStorage(`merchant${this.settings.receipt.print_child_merchant_details ? order?.merchant_id || this.merchant.id : this.merchant.id}digitalStamp`)
				const id = `${this.partner.name}-Order-Invoice-${order.ref_code || order.receipt_code.substr(-10)}-${Date.now()}`
				const body = document.querySelector('body')
				const iframe = document.createElement('iframe')
				let component = OrderInvoice

				if (this.merchant.countryCode === 'SA' || this.merchant.countryCode === 'QA') {
					component = OrderInvoiceSA

					if (['deliveryNote', 'simplifiedInvoice'].includes(invoiceType)) {
						component = OrderInvoiceSimplified
					}
				}

				if (this.settings.general.invoice_template && this.settings.general.invoice_template !== 'template_1' && !invoiceType) {
					const componentName = `OrderInvoice${(this.settings.general.invoice_template.charAt(0).toUpperCase() + this.settings.general.invoice_template.slice(1)).replace(/_/g, '')}`

					component = this.invoiceComponents[componentName]
				}

				const ComponentClass = Vue.extend(component)
				const instance = new ComponentClass({
					propsData: {
						i18n: this.$i18n,
						bridge: this.$bridge,
						moment: this.$moment,
						currency: this.$currency,
						customerInvoice: invoiceType === 'simplifiedInvoice',
						data: { id, type, order, merchantLogo, merchantLetterHeadImage, digitalSignature, merchantdigitalStamp }
					}
				})

				instance.$mount()
				instance.$on('destroy', () => {
					body.removeChild(iframe)
					instance.$destroy()
				})

				iframe.id = id
				iframe.srcdoc = instance.$el.outerHTML
				iframe.onload = instance.renderInvoice
				body.appendChild(iframe)
			},
			async checkNetworkTime () {
				if (typeof this.$bridge.getNetworkTime === 'function') {
					try {
						const networkTime = await this.$bridge.getNetworkTime()
						const date = new Date()
						const diff = this.$moment(date).diff(networkTime) / 1000 // in seconds

						this.showInvalidTimeModal = !!(diff < -60 || diff > 60)
					} catch (err) {
						console.error(err)
						this.showInvalidTimeModal = false
					}
				}
			},
			async checkSubscription ($event) {
				const btn = $event.currentTarget

				btn.disabled = true
				btn.classList.add('running')

				const cb = () => {
					this.$store.dispatch('checkSubscription')
					this.$store.commit('resetProgress')
					btn.disabled = false
					btn.classList.remove('running')
				}

				this.sync(await this.getSyncOptions('merchant-details')).then(cb).catch(cb)
			},
			async initReleaseNotes (version, force = false) {
				const releaseNotesName = `release-notes-v${version || this.frontendAppVersion}`
				const frontendAppVersion = localStorage.getItem('frontendAppVersion')

				if (frontendAppVersion !== this.frontendAppVersion || force) {
					if (this.env !== 'production') {
						document.addEventListener('change', (e) => {
							if (e.target?.classList.contains('nuxt-content-editor')) {
								setTimeout(async () => {
									this.releaseNotesDoc = await this.$content(releaseNotesName).fetch()
								}, 1000)
							}
						})
					}

					try {
						this.releaseNotesDoc = await this.$content(releaseNotesName).fetch()
						this.$bvModal.show('release-notes-modal')
						localStorage.setItem('frontendAppVersion', this.frontendAppVersion)
					} catch (err) {
						console.error(err)
					}
				}
			},
			async getDocumentation () {
				this.documentationDoc = await this.$content('documentation').fetch()
			}
		}
	}
