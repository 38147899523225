import { render, staticRenderFns } from "./settings.vue?vue&type=template&id=d0fbda04"
import script from "./settings.vue?vue&type=script&lang=js"
export * from "./settings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Printer: require('/home/ubuntu/complete-nuxt-js/components/printer.vue').default,SyncDetails: require('/home/ubuntu/complete-nuxt-js/components/sync-details.vue').default,Scanner: require('/home/ubuntu/complete-nuxt-js/components/scanner.vue').default,WeighingScale: require('/home/ubuntu/complete-nuxt-js/components/weighing-scale.vue').default,InternationalBancard: require('/home/ubuntu/complete-nuxt-js/components/international-bancard.vue').default,Tidypay: require('/home/ubuntu/complete-nuxt-js/components/tidypay.vue').default,Skyband: require('/home/ubuntu/complete-nuxt-js/components/skyband.vue').default,Pinelabs: require('/home/ubuntu/complete-nuxt-js/components/pinelabs.vue').default,Clover: require('/home/ubuntu/complete-nuxt-js/components/clover.vue').default,Keyboard: require('/home/ubuntu/complete-nuxt-js/components/keyboard.vue').default,OnlinePlatform: require('/home/ubuntu/complete-nuxt-js/components/online-platform.vue').default,Import: require('/home/ubuntu/complete-nuxt-js/components/import.vue').default,EmployeeList: require('/home/ubuntu/complete-nuxt-js/components/employee-list.vue').default,FloorList: require('/home/ubuntu/complete-nuxt-js/components/floor-list.vue').default,TableList: require('/home/ubuntu/complete-nuxt-js/components/table-list.vue').default,TaxList: require('/home/ubuntu/complete-nuxt-js/components/tax-list.vue').default,ChargeList: require('/home/ubuntu/complete-nuxt-js/components/charge-list.vue').default,DiscountList: require('/home/ubuntu/complete-nuxt-js/components/discount-list.vue').default,BrandList: require('/home/ubuntu/complete-nuxt-js/components/brand-list.vue').default,CategoryList: require('/home/ubuntu/complete-nuxt-js/components/category-list.vue').default,PriceCategoryList: require('/home/ubuntu/complete-nuxt-js/components/price-category-list.vue').default})
